import React from 'react'
import Helmet from 'react-helmet'
import classNames from 'classnames'
import {
  Document as RichTextDocument,
  BLOCKS,
} from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import AuthProvider from 'contexts/AuthProvider'
import UserProvider from 'contexts/UserProvider'
import Layout from 'components/Layout'
import LinkBack from 'components/LinkBack'
import ReactionButtons from 'components/ReactionButtons'

import 'styles/marketplace/product-template.scss'

type Props = {
  pageContext: {
    product: {
      categorySlug: string
      categoryTitle: string
      title: string
      subtitle: string
      description: { raw: string }
      images: {
        file: {
          url: string
        }
      }[]
      productWebsite: string
    }
    webview?: boolean
  }
  location: {
    href: string
  }
}

export default function ProductPage(props: Props) {
  const {
    pageContext: {
      product: {
        categorySlug,
        categoryTitle,
        title,
        subtitle,
        description,
        images,
        productWebsite,
      },
      webview,
    },
    location: { href },
  } = props

  const productWebsiteDomain = productWebsite
    .replace(/https?:\/\/(?:www\.)?/, '')
    .split('/')[0]

  const descriptionOptions = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_1]: (_: any, children: React.ReactNode) => (
        <h2 className="marketplace-product__description__heading">
          {children}
        </h2>
      ),
    },
  }

  return (
    <AuthProvider>
      <UserProvider>
        <Layout
          className={webview ? 'product-page--webview' : 'product-page'}
          webview={webview}
        >
          <Helmet>
            <title>Jevitty Marketplace | {title}</title>
            <meta name="robots" content="noindex,nofollow" />
          </Helmet>

          <div
            className={classNames('row row-cols-1', {
              'row-cols-md-2': !webview,
            })}
          >
            <div className="col">
              <LinkBack webview={webview} to={`/marketplace/${categorySlug}/`}>
                &#8592; Back to {categoryTitle}
              </LinkBack>

              {images && (
                <div className="product-page__images">
                  {images.map((image, index) => (
                    <div
                      className="product-page__images__image-wrapper"
                      key={index}
                    >
                      <img
                        className="product-page__images__image"
                        src={image.file.url}
                        alt={`${title} - ${index}`}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="col">
              <div className="product-page__content">
                <h1 className="product-page__title">{title}</h1>

                {subtitle && (
                  <h2 className="product-page__subtitle">By {subtitle}</h2>
                )}

                {productWebsite && (
                  <a
                    href={productWebsite}
                    className={classNames(
                      'product-page__button btn btn-primary',
                      { 'btn-lg': webview },
                    )}
                  >
                    {productWebsite.includes('amazon.com')
                      ? 'Buy on Amazon'
                      : `Learn more on ${productWebsiteDomain}`}
                  </a>
                )}

                {description && (
                  <div className="product-page__description">
                    {documentToReactComponents(
                      JSON.parse(description.raw) as RichTextDocument,
                      descriptionOptions,
                    )}
                  </div>
                )}

                <ReactionButtons url={href} />
              </div>
            </div>
          </div>
        </Layout>
      </UserProvider>
    </AuthProvider>
  )
}
